<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h6 primary white--text"> Escolha um diário </v-card-title>
        <v-select
          class="px-3 my-5"
          :items="gestoesDeAulas"
          item-text="professor.nome"
          item-value="id"
          :hint="`${gestaoSelecionada.turma.descricao}, ${gestaoSelecionada.disciplina.descricao}`"
          return-object
          label="Selecione o campo abaixo"
          v-model="gestaoSelecionada"
        >
        </v-select>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('dialogChange', false)"> Fechar </v-btn>
          <v-btn color="primary" text @click="navegar()"> Abrir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    turma_id: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      gestoesDeAulas: [],
      confirmDialog: false,
      gestaoSelecionada: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
        id: null,
      },
    };
  },
  watch: {
    turma_id: {
      handler() {
        this.listarGestaoDeAulasDaTurma();
      },
    },
  },
  methods: {
    async listarGestaoDeAulasDaTurma() {
      const gestoes = await this.$services.gestoesDeAulasService.listarGestaoDeAulasDaTurma(
        this.turma_id
      );
      this.gestoesDeAulas = gestoes.filter(
        (gestao) => gestao.professor && gestao.professor.id !== null
      );
      this.gestoesDeAulas.forEach((gestao) => {
        const idProfessor = gestao.professor.id;
        // console.log("ID do professor:", idProfessor);
      });
    },
    async navegar() {
      // this.gestoesDeAulas = await this.$services.aulasService.syncAll(this.gestaoSelecionada);
      this.$router.push({
        name: "relatorios.visualizar",
        params: {
          gestaoDeAulasId: this.gestaoSelecionada.id,
          turmaId: this.gestaoSelecionada.turma_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
